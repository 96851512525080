import React, { FC, ReactElement, useEffect } from "react";
import { Form, FormInstance, Input } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import IconButton from "../IconButton/IconButton";

type PropsType = {
    onSearch: (data: { searchValue: string }) => void;
    form?: FormInstance<{ searchValue: string }>;
};

const InputSearch: FC<PropsType> = ({ onSearch, form }): ReactElement => {
    useEffect(() => {
        const handleFormSubmit = () => {
            form?.submit();
        };

        const debounceTimer = setTimeout(handleFormSubmit, 500); // Add a debounce timer to avoid triggering the search on every keystroke

        return () => {
            clearTimeout(debounceTimer);
        };
    }, [form]);

    return (
        <Form onFinish={onSearch} form={form} style={{ display: "flex", alignItems: "center" }}>
            <Input.Group compact style={{ flex: 1 }}>
                <Form.Item name={"searchValue"} style={{ margin: 0 }}>
                    <Input placeholder={"Search..."} />
                </Form.Item>
            </Input.Group>
            <IconButton title={"Search"} icon={<SearchOutlined />} />
        </Form>
    );
};

export default InputSearch;
