import React, {FC, ReactElement, useEffect} from "react";
import { Col, Row, Typography } from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";

import ContentWrapper from "../../components/ContentWrapper/ContentWrapper";
import ContentTitle from "../../components/ContentTitle/ContentTitle";

const Contacts: FC = (): ReactElement => {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    
    return (
        <ContentWrapper>
            <ContentTitle icon={<InfoCircleOutlined />} title={"Contacts"} />
            <Row gutter={32}>
                <Col span={12}>
                    <div>
                        <Typography.Text strong>{"Mobile: "}</Typography.Text>
                        <Typography.Text>(+91) 9717471155</Typography.Text>
                    </div>
                    <div>
                        <Typography.Text strong>{"E-mail: "}</Typography.Text>
                        <Typography.Text>bharatwashbusiness@gmail.com</Typography.Text>
                    </div>
                    <div>
                        <Typography.Text strong>{"website: "}</Typography.Text>
                        <Typography.Text>https://www.bharatwash.com</Typography.Text>
                    </div>
                    {/* <div style={{ marginTop: 16 }}>
                        <Typography.Text strong>Working time</Typography.Text>
                    </div> */}
                    <div>
                        <br/>
                        <Typography.Text>
                            Contact us on whatsapp for order related queries. <br />
                            You can directly go to Whatsapp by clicking on the bottom right of your screen. 
                        </Typography.Text>
                    </div>
                    <div style={{ marginTop: 16 }}>
                        <Typography.Text strong>Delivery</Typography.Text>
                    </div>
                    <div>
                        <Typography.Text>It will take 1-3 days to deliver the order or you can also order on AMAZON.IN.</Typography.Text>
                    </div>
                </Col>
            </Row>
        </ContentWrapper>
    );
};

export default Contacts;
