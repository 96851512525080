import React from 'react';
import { WhatsAppOutlined } from '@ant-design/icons';
import './WhatsAppButton.css'; // Import your CSS file

const WhatsAppButton: React.FC = () => {
  const phoneNumber = '9717471155'; // Replace with your WhatsApp number
  const message = encodeURIComponent('Hello, I have a question.'); // Replace with your pre-filled message

  const whatsappUrl = `https://wa.me/${phoneNumber}?text=${message}`;

  return (
    <a href={whatsappUrl} target="_blank" rel="noopener noreferrer" className="whatsapp-button">
      <WhatsAppOutlined className="whatsapp-icon" />
    </a>
  );
};

export default WhatsAppButton;
