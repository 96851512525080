import { createAsyncThunk } from "@reduxjs/toolkit";

import RequestService from "../../utils/request-service";
import { PERFUMES, PERFUMES_GRAPHQL_PERFUME, PERFUMES_REVIEWS } from "../../constants/urlConstants";
import { getPerfumeByQuery } from "../../utils/graphql-query/perfume-query";
import { FullPerfumeResponse, ReviewResponse } from "../../types/types";

// export const fetchPerfume = createAsyncThunk<Partial<FullPerfumeResponse>, string, { rejectValue: string }>(
//     "perfume/fetchPerfume",
//     async (perfumeId, thunkApi) => {
//         try {
//             const response = await RequestService.get(`${PERFUMES}/${perfumeId}`);
//             return response.data;
//         } catch (error) {
//             return thunkApi.rejectWithValue(error.response.data);
//         }
//     }
// );

export const fetchPerfume = createAsyncThunk<Partial<FullPerfumeResponse>, string, { rejectValue: string }>(
    "perfume/fetchPerfume",
    async (perfumeId, thunkApi) => {
        try {
            // Simulate a hardcoded response
            const hardcodedResponse: Partial<FullPerfumeResponse> = {
                year: 123,
                country: "Hardcoded Country",
                perfumeGender: "Hardcoded Gender",
                fragranceTopNotes: "Hardcoded Top Notes",
                fragranceMiddleNotes: "Hardcoded Middle Notes",
                fragranceBaseNotes: "Hardcoded Base Notes",
                description: "Hardcoded Description",
                type: "Hardcoded Type",
                file: "Hardcoded File"
            };

            // Simulate an asynchronous delay
            await new Promise(resolve => setTimeout(resolve, 2000));

            return hardcodedResponse;
        } catch (error) {
            return thunkApi.rejectWithValue(error.response.data);
        }
    }
);


// export const fetchReviewsByPerfumeId = createAsyncThunk<Array<ReviewResponse>, string>(
//     "perfume/fetchReviewsByPerfumeId",
//     async (perfumeId) => {
//         const response = await RequestService.get(`${PERFUMES_REVIEWS}/${perfumeId}`);
//         return response.data;
//     }
// );

// GraphQL thunks
// export const fetchPerfumeByQuery = createAsyncThunk<Partial<FullPerfumeResponse>, string, { rejectValue: string }>(
//     "perfume/fetchPerfumeByQuery",
//     async (perfumeId, thunkApi) => {
//         try {
//             const response = await RequestService.post(PERFUMES_GRAPHQL_PERFUME, {
//                 query: getPerfumeByQuery(perfumeId)
//             });
//             return response.data.data.perfume;
//         } catch (error) {
//             return thunkApi.rejectWithValue(error.response.data);
//         }
//     }
// );

export const fetchPerfumeByQuery = createAsyncThunk<Partial<FullPerfumeResponse>, string, { rejectValue: string }>(
    "perfume/fetchPerfumeByQuery",
    async (perfumeId, thunkApi) => {
        try {
            // Simulate a hardcoded response
            const hardcodedResponse: Partial<FullPerfumeResponse> = {
                year: 123,
                country: "Hardcoded Country",
                perfumeGender: "Hardcoded Gender",
                fragranceTopNotes: "Hardcoded Top Notes",
                fragranceMiddleNotes: "Hardcoded Middle Notes",
                fragranceBaseNotes: "Hardcoded Base Notes",
                description: "Hardcoded Description",
                type: "Hardcoded Type",
                file: "Hardcoded File"
            };

            // Simulate an asynchronous delay
            await new Promise(resolve => setTimeout(resolve, 2000));

            return hardcodedResponse;
        } catch (error) {
            return thunkApi.rejectWithValue(error.response.data);
        }
    }
);

