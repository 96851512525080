import React, { FC, ReactElement } from "react";
import { useDispatch, useSelector } from "react-redux";
import { LoginOutlined, LogoutOutlined, ShoppingCartOutlined, UserAddOutlined, UserOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import { Affix, Badge, Col, Row, Space } from "antd";

import { selectUserFromUserState } from "../../redux-toolkit/user/user-selector";
import { selectCartItemsCount } from "../../redux-toolkit/cart/cart-selector";
import { logoutSuccess } from "../../redux-toolkit/user/user-slice";
import { ACCOUNT, BASE, CONTACTS, LOGIN, MENU, REGISTRATION } from "../../constants/routeConstants";
import { CART } from "../../constants/urlConstants";
import "./NavBar.scss";
import styled from 'styled-components';
import logo from './logo.jpg';


const NavBar: FC = (): ReactElement => {
    const dispatch = useDispatch();
    const usersData = useSelector(selectUserFromUserState);
    const cartItemsCount = useSelector(selectCartItemsCount);

    const handleLogout = (): void => {
        localStorage.removeItem("token");
        dispatch(logoutSuccess());
    };

    return (
        <Affix>
          <div className="navbar-wrapper">
            <Row style={{ padding: '10px 50px' }}>
              <Col span={8}>
                <div className="logo-container">
                  <img src={logo} alt="Logo" className="logo" />
                </div>
              </Col>
              <Col span={16} style={{ textAlign: 'right' }}>
                <ul className="navbar-links">
                  <li>
                    <Link to="/" className="navbar-link">
                      HOME
                    </Link>
                  </li>
                  <li>
                    <Link to={{ pathname: MENU, state: { id: 'all' } }} className="navbar-link">
                      PRODUCTS
                    </Link>
                  </li>
                  <li>
                    <Link to="/contacts" className="navbar-link">
                      CONTACTS
                    </Link>
                  </li>
                </ul>
              </Col>
            </Row>
          </div>
        </Affix>
      );
};

export default NavBar;
