import React from "react";
import axios, { Method } from "axios";

import { API_BASE_URL } from "../constants/urlConstants";

class RequestService {
    get = (url: string, isAuthRequired: boolean = false, contentType: string = "application/json") => {
        return new Promise<any>((resolve, reject) => {
            try {
                // Simulate a hardcoded response
                const hardcodedResponse = {
                    year: 123,
                    country: "Hardcoded Country",
                    perfumeGender: "Hardcoded Gender",
                    fragranceTopNotes: "Hardcoded Top Notes",
                    fragranceMiddleNotes: "Hardcoded Middle Notes",
                    fragranceBaseNotes: "Hardcoded Base Notes",
                    description: "Hardcoded Description",
                    type: "Hardcoded Type",
                    file: "Hardcoded File"
                };

                // Simulate an asynchronous delay
                setTimeout(() => {
                    resolve({ data: hardcodedResponse });
                }, 2000);
            } catch (error) {
                reject(error);
            }
        });
    };

    post = (url: string, body: any, isAuthRequired: boolean = false, contentType: string = "application/json") => {
        return createRequest("POST", url, body, isAuthRequired, contentType);
    };

    put = (url: string, body: any, isAuthRequired: boolean = false, contentType: string = "application/json") => {
        return createRequest("PUT", url, body, isAuthRequired, contentType);
    };

    delete = (url: string, isAuthRequired: boolean = false, contentType: string = "application/json") => {
        return createRequest("DELETE", url, null, isAuthRequired, contentType);
    };
}

const createRequest = (method: Method, url: string, body: any, isAuthRequired: boolean, contentType: string) => {
    return axios({
        method: method,
        url: API_BASE_URL + url,
        data: body,
        headers: setHeader(isAuthRequired, contentType)
    });
};

const setHeader = (isAuthRequired: boolean, contentType: string) => {
    if (isAuthRequired) {
        axios.defaults.headers.common["Authorization"] = localStorage.getItem("token");
    } else {
        delete axios.defaults.headers.common["Authorization"];
    }
    axios.defaults.headers.common["Content-Type"] = contentType;
};

export default new RequestService();
