import React, { FC, ReactElement, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Form } from "antd";
import SockJS from "sockjs-client";
import { CompatClient, Stomp } from "@stomp/stompjs";

import ContentWrapper from "../../components/ContentWrapper/ContentWrapper";
import {
    selectIsPerfumeLoaded,
    selectIsPerfumeLoading,
    selectPerfume,
    selectPerfumeError,
    selectPerfumeErrorMessage,
    selectReviews
} from "../../redux-toolkit/perfume/perfume-selector";
import { selectIsReviewAdded, selectReviewErrors } from "../../redux-toolkit/user/user-selector";
import { fetchPerfume } from "../../redux-toolkit/perfume/perfume-thunks";
import { resetInputForm } from "../../redux-toolkit/user/user-slice";
import { WEBSOCKET_URL } from "../../constants/urlConstants";
import { resetPerfumeState, setReview } from "../../redux-toolkit/perfume/perfume-slice";
import Spinner from "../../components/Spinner/Spinner";
import ErrorMessage from "./ErrorMessage/ErrorMessage";
import ProductInfo from "./ProductInfo/ProductInfo";
import ProductReviews from "./ProductReviews/ProductReviews";
import { addReviewToPerfume } from "../../redux-toolkit/user/user-thunks";
import { useCart } from "../../hooks/useCart";
import "./Product.css";
import pic2 from './pic2.jpg';
import pic1 from './pic1.jpg';
import pic3 from './pic3.jpg';
import pic4 from './pic4.jpg';
import pic5 from './pic5.jpg';


let stompClient: CompatClient | null = null;

export interface ReviewData {
    author: string;
    message: string;
    rating: number;
}

const Product: FC = (): ReactElement => {
    const dispatch = useDispatch();
    const [form] = Form.useForm();
    const params = useParams<{ id: string }>();
    const perfume = useSelector(selectPerfume);
    const reviews = useSelector(selectReviews);
    const isPerfumeLoading = useSelector(selectIsPerfumeLoading);
    const isPerfumeLoaded = useSelector(selectIsPerfumeLoaded);
    // const isPerfumeError = useSelector(selectPerfumeError);
    const isPerfumeError = false;
    // const errorMessage = useSelector(selectPerfumeErrorMessage);
    const errorMessage = "Try Again!!";
    const reviewErrors = useSelector(selectReviewErrors);
    const isReviewAdded = useSelector(selectIsReviewAdded);
    const { addToCart } = useCart(perfume?.id!);

    useEffect(() => {
        // GraphQL example
        // dispatch(fetchPerfumeByQuery(params.id));
        dispatch(fetchPerfume(params.id));
        dispatch(resetInputForm());
        window.scrollTo(0, 0);
        // const socket = new SockJS(WEBSOCKET_URL);
        // stompClient = Stomp.over(socket);
        // stompClient.connect({}, () => {
        //     stompClient?.subscribe("/topic/reviews/" + params.id, (response: any) => {
        //         dispatch(setReview(JSON.parse(response.body)));
        //     });
        // });

        return () => {
            // stompClient?.disconnect();
            dispatch(resetPerfumeState());
        };
    }, []);

    useEffect(() => {
        if (isPerfumeLoaded) {
//             dispatch(fetchReviewsByPerfumeId(params.id));
        }
    }, [isPerfumeLoaded]);

    useEffect(() => {
        form.resetFields();
    }, [isReviewAdded]);

    const addReview = (data: ReviewData): void => {
        dispatch(addReviewToPerfume({ perfumeId: params.id, ...data }));
    };

    // return (
    //     <ContentWrapper>
    //         {isPerfumeLoading ? (
    //             <Spinner />
    //         ) : (
    //             <>
    //                 {isPerfumeError ? (
    //                     <ErrorMessage errorMessage={errorMessage} />
    //                 ) : (
    //                     <>
    //                         <ProductInfo perfume={perfume} reviewsLength={reviews.length} addToCart={addToCart} />
    //                         <ProductReviews
    //                             reviews={reviews}
    //                             reviewErrors={reviewErrors}
    //                             addReview={addReview}
    //                             form={form}
    //                         />
    //                     </>
    //                 )}
    //             </>
    //         )}
    //     </ContentWrapper>
    // );

    return (
        <ContentWrapper>
          {isPerfumeLoading ? (
            <Spinner />
          ) : (
            <>
              {isPerfumeError ? (
                <ErrorMessage errorMessage={errorMessage} />
              ) : (
                <>
                  {perfume && (
                    <>
                      {1 === parseInt(params.id) && (
                        <>
                          <ProductInfo
                            perfume={{
                              id: 1,
                              perfumeTitle: "Toilet Cleaner",
                              perfumer: "BharatWash",
                              price: 190,
                              perfumeRating: 5,
                              filename: pic1,
                              reviewsCount: 10,
                              volume: "1000",
                              year: 2023,
                              country: "India",
                              description: "This is a description of Perfume 1.",
                              file: null,
                            }}
                            reviewsLength={reviews.length}
                            addToCart={addToCart}
                          />
                          <ProductReviews
                            reviews={reviews}
                            reviewErrors={reviewErrors}
                            addReview={addReview}
                            form={form}
                          />
                        </>
                      )},
                      {2 === parseInt(params.id) && (
                        <>
                          <ProductInfo
                            perfume={{
                              id: 2,
                              perfumeTitle: "Bathroom + Tile Cleaner",
                              perfumer: "BharatWash",
                              price: 92,
                              perfumeRating: 4.5,
                              filename: pic2,
                              reviewsCount: 10,
                              volume: "500",
                              year: 2022,
                              country: "France",
                              perfumeGender: "Male",
                              fragranceTopNotes: "Citrus",
                              fragranceMiddleNotes: "Spices",
                              fragranceBaseNotes: "Woody",
                              description: "This is a description of Perfume 1.",
                              file: null,
                            }}
                            reviewsLength={reviews.length}
                            addToCart={addToCart}
                          />
                          <ProductReviews
                            reviews={reviews}
                            reviewErrors={reviewErrors}
                            addReview={addReview}
                            form={form}
                          />
                        </>
                      )},
                      {3 === parseInt(params.id) && (
                        <>
                          <ProductInfo
                            perfume={{
                              id: 3,
                              perfumeTitle: "Liquid Detergent",
                              perfumer: "BharatWash",
                              price: 230,
                              perfumeRating: 4.5,
                              filename: pic3,
                              reviewsCount: 10,
                              volume: "1000",
                              file: null,
                            }}
                            reviewsLength={reviews.length}
                            addToCart={addToCart}
                          />
                          <ProductReviews
                            reviews={reviews}
                            reviewErrors={reviewErrors}
                            addReview={addReview}
                            form={form}
                          />
                        </>
                      )},
                      {4 === parseInt(params.id) && (
                        <>
                          <ProductInfo
                            perfume={{
                              id: 4,
                              perfumeTitle: "Glass Cleaner",
                              perfumer: "BharatWash",
                              price: 122,
                              perfumeRating: 4.5,
                              filename: pic4,
                              reviewsCount: 10,
                              volume: "500",
                              file: null,
                            }}
                            reviewsLength={reviews.length}
                            addToCart={addToCart}
                          />
                          <ProductReviews
                            reviews={reviews}
                            reviewErrors={reviewErrors}
                            addReview={addReview}
                            form={form}
                          />
                        </>
                      )},
                      {5 === parseInt(params.id) && (
                        <>
                          <ProductInfo
                            perfume={{
                              id: 3,
                              perfumeTitle: "Dishwash Gel",
                              perfumer: "BharatWash",
                              price: 135,
                              perfumeRating: 4.5,
                              filename: pic5,
                              reviewsCount: 10,
                              volume: "500",
                              file: null,
                            }}
                            reviewsLength={reviews.length}
                            addToCart={addToCart}
                          />
                          <ProductReviews
                            reviews={reviews}
                            reviewErrors={reviewErrors}
                            addReview={addReview}
                            form={form}
                          />
                        </>
                      )}
                    </>
                  )}
                </>
              )}
            </>
          )}
        </ContentWrapper>
      );
};

export default Product;
