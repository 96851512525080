import React, { FC, ReactElement } from "react";
import { Carousel } from "antd";
import { Link } from "react-router-dom";

import { PRODUCT } from "../../../constants/routeConstants";
import "./CarouselImageSlider.css";

import pic1 from './pic1.jpg';
import pic2 from './pic2.jpg';
import pic3 from './pic3.jpg';
import pic4 from './pic4.jpg';


export const sliderItems = [
    {
        id: "1",
        name: "Photo 1",
        url: pic1
    },
    {
        id: "2",
        name: "Photo 2",
        url: pic2
    },
    {
        id: "3",
        name: "Photo 3",
        url: pic3
    },
    {
        id: "4",
        name: "Photo 4",
        url: pic4
    }
];

const CarouselImageSlider: FC = (): ReactElement => {
    return (
        <Carousel>
            {sliderItems.map((item) => (
                <div key={item.id} className={"carousel-item-wrapper"}>
                    {/* <Link to={`${PRODUCT}/${item.id}`} className={"carousel-link"} /> */}
                    <img src={item.url} alt={item.name} style={{ width: '500px', height: 'auto', display: 'block', margin: '0 auto' }} />
                </div>
            ))}
        </Carousel>
    );
};

export default CarouselImageSlider;
