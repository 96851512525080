import { createAsyncThunk } from "@reduxjs/toolkit";

import RequestService from "../../utils/request-service";

import pic1 from './pic1.jpg';
import pic2 from './pic2.jpg';
import pic3 from './pic3.jpg';
import pic4 from './pic4.jpg';
import pic5 from './pic5.jpg';


import {
    PERFUMES,
    PERFUMES_GRAPHQL_IDS,
    PERFUMES_GRAPHQL_PERFUMES,
    PERFUMES_IDS,
    PERFUMES_SEARCH,
    PERFUMES_SEARCH_TEXT
} from "../../constants/urlConstants";
import { FilterParamsType, HeaderResponse, PerfumeResponse, PerfumesSearchRequest } from "../../types/types";
import { gePerfumesByIdsQuery, getAllPerfumesByQuery } from "../../utils/graphql-query/perfume-query";

// export const fetchPerfumes = createAsyncThunk<HeaderResponse<PerfumeResponse>, number>(
//     "perfumes/fetchPerfumes",
//     async (page) => {
//         const response = await RequestService.get(`${PERFUMES}?page=${page}`);
//         return {
//             items: response.data,
//             pagesCount: parseInt(response.headers["page-total-count"]),
//             totalElements: parseInt(response.headers["page-total-elements"])
//         };
//     }
// );

export const fetchPerfumes = createAsyncThunk<HeaderResponse<PerfumeResponse>, number>(
  "perfumes/fetchPerfumes",
  async (page) => {

    const responseData: PerfumeResponse[] = [
      { 
        id: 1, 
        perfumeTitle: "Toilet Cleaner", 
        perfumer: "BharatWash", 
        price: 190, 
        perfumeRating: 4.5,
        filename: pic1,
        reviewsCount: 10,
        volume: "1000ml"
      },
      { 
        id: 2, 
        perfumeTitle: "Bathroom + Tile Cleaner", 
        perfumer: "BharatWash", 
        price: 92, 
        perfumeRating: 4.0,
        filename: pic2,
        reviewsCount: 5,
        volume: "500ml"
      },
      { 
        id: 3, 
        perfumeTitle: "Liquid Detergent", 
        perfumer: "BharatWash", 
        price: 230, 
        perfumeRating: 4.0,
        filename: pic3,
        reviewsCount: 5,
        volume: "1000ml"
      },
      { 
        id: 4, 
        perfumeTitle: "Glass Cleaner", 
        perfumer: "BharatWash", 
        price: 122, 
        perfumeRating: 4.0,
        filename: pic4,
        reviewsCount: 5,
        volume: "500ml"
      },
      { 
        id: 5, 
        perfumeTitle: "Dishwash Gel", 
        perfumer: "BharatWash", 
        price: 135, 
        perfumeRating: 4.0,
        filename: pic5,
        reviewsCount: 5,
        volume: "500ml"
      },
      // Add more hardcoded perfume data as needed
    ];
      // const response = await RequestService.get(`${PERFUMES}?page=${page}`);
      return {
          items: responseData,
          pagesCount: 2,
          totalElements: 2
      };
  }
);

// export const fetchPerfumesByIds = createAsyncThunk<Array<PerfumeResponse>, Array<number>>(
//     "perfumes/fetchPerfumesByIds",
//     async (ids) => {
//         const response = await RequestService.post(PERFUMES_IDS, ids);
//         return response.data;
//     }
// );

export const fetchPerfumesByIds = createAsyncThunk<Array<PerfumeResponse>, Array<number>>(
  "perfumes/fetchPerfumesByIds",
  async (ids) => {

    const responseData: PerfumeResponse[] = [
      { 
        id: 1, 
        perfumeTitle: "Toilet Cleaner", 
        perfumer: "BharatWash", 
        price: 190, 
        perfumeRating: 4.5,
        filename: pic1,
        reviewsCount: 10,
        volume: "1000ml"
      },
      { 
        id: 2, 
        perfumeTitle: "Bathroom + Tile Cleaner", 
        perfumer: "BharatWash", 
        price: 92, 
        perfumeRating: 4.0,
        filename: pic2,
        reviewsCount: 5,
        volume: "500ml"
      },
      { 
        id: 3, 
        perfumeTitle: "Liquid Detergent", 
        perfumer: "BharatWash", 
        price: 230, 
        perfumeRating: 4.0,
        filename: pic3,
        reviewsCount: 5,
        volume: "1000ml"
      },
      { 
        id: 4, 
        perfumeTitle: "Glass Cleaner", 
        perfumer: "BharatWash", 
        price: 122, 
        perfumeRating: 4.0,
        filename: pic4,
        reviewsCount: 5,
        volume: "500ml"
      },
      { 
        id: 5, 
        perfumeTitle: "Dishwash Gel", 
        perfumer: "BharatWash", 
        price: 135, 
        perfumeRating: 4.0,
        filename: pic5,
        reviewsCount: 5,
        volume: "500ml"
      },
      // Add more hardcoded perfume data as needed
    ];
      return responseData;
  }
);



// hardcoded as of now
export const fetchPerfumesByFilterParams = createAsyncThunk<
  HeaderResponse<PerfumeResponse>,
  FilterParamsType>("perfumes/fetchPerfumesByFilterParams", async (filter) => {
  // Hardcoded response data
  const responseData: PerfumeResponse[] = [
    { 
      id: 1, 
      perfumeTitle: "Toilet Cleaner", 
      perfumer: "BharatWash", 
      price: 190, 
      perfumeRating: 4.5,
      filename: pic1,
      reviewsCount: 10,
      volume: "1000ml"
    },
    { 
      id: 2, 
      perfumeTitle: "Bathroom + Tile Cleaner", 
      perfumer: "BharatWash", 
      price: 92, 
      perfumeRating: 4.0,
      filename: pic2,
      reviewsCount: 5,
      volume: "500ml"
    },
    { 
      id: 3, 
      perfumeTitle: "Liquid Detergent", 
      perfumer: "BharatWash", 
      price: 230, 
      perfumeRating: 4.0,
      filename: pic3,
      reviewsCount: 5,
      volume: "1000ml"
    },
    { 
      id: 4, 
      perfumeTitle: "Glass Cleaner", 
      perfumer: "BharatWash", 
      price: 122, 
      perfumeRating: 4.0,
      filename: pic4,
      reviewsCount: 5,
      volume: "500ml"
    },
    { 
      id: 5, 
      perfumeTitle: "Dishwash Gel", 
      perfumer: "BharatWash", 
      price: 135, 
      perfumeRating: 4.0,
      filename: pic5,
      reviewsCount: 5,
      volume: "500ml"
    },
    // Add more hardcoded perfume data as needed
  ];

  // Calculate pagination values (assuming static response data)
  const pageSize = 10; // Number of items per page
  const pagesCount = Math.ceil(responseData.length / pageSize);
  const totalElements = responseData.length;

  // Simulate delay to mimic API call
  await new Promise((resolve) => setTimeout(resolve, 1000));

  return {
    items: responseData,
    pagesCount,
    totalElements,
  };
});

// export const fetchPerfumesByFilterParams = createAsyncThunk<HeaderResponse<PerfumeResponse>, FilterParamsType>(
//     "perfumes/fetchPerfumesByFilterParams",
//     async (filter) => {
//         const response = await RequestService.post(`${PERFUMES_SEARCH}?page=${filter.currentPage}`, filter);
//         return {
//             items: response.data,
//             pagesCount: parseInt(response.headers["page-total-count"]),
//             totalElements: parseInt(response.headers["page-total-elements"])
//         };
//     }
// );

export const fetchPerfumesByInputText = createAsyncThunk<HeaderResponse<PerfumeResponse>, PerfumesSearchRequest>(
    "perfumes/fetchPerfumesByInputText",
    async (filter) => {
        // Hardcoded response data
        const responseData: PerfumeResponse[] = [
          { 
            id: 1, 
            perfumeTitle: "Toilet Cleaner", 
            perfumer: "BharatWash", 
            price: 190, 
            perfumeRating: 4.5,
            filename: pic1,
            reviewsCount: 10,
            volume: "1000ml"
          },
          { 
            id: 2, 
            perfumeTitle: "Bathroom + Tile Cleaner", 
            perfumer: "BharatWash", 
            price: 92, 
            perfumeRating: 4.0,
            filename: pic2,
            reviewsCount: 5,
            volume: "500ml"
          },
          { 
            id: 3, 
            perfumeTitle: "Liquid Detergent", 
            perfumer: "BharatWash", 
            price: 230, 
            perfumeRating: 4.0,
            filename: pic3,
            reviewsCount: 5,
            volume: "1000ml"
          },
          { 
            id: 4, 
            perfumeTitle: "Glass Cleaner", 
            perfumer: "BharatWash", 
            price: 122, 
            perfumeRating: 4.0,
            filename: pic4,
            reviewsCount: 5,
            volume: "500ml"
          },
          { 
            id: 5, 
            perfumeTitle: "Dishwash Gel", 
            perfumer: "BharatWash", 
            price: 135, 
            perfumeRating: 4.0,
            filename: pic5,
            reviewsCount: 5,
            volume: "500ml"
          },
          // Add more hardcoded perfume data as needed
        ];
      
        // Calculate pagination values (assuming static response data)
        const pageSize = 10; // Number of items per page
        const pagesCount = Math.ceil(responseData.length / pageSize);
        const totalElements = responseData.length;
      
        // Simulate delay to mimic API call
        await new Promise((resolve) => setTimeout(resolve, 1000));
      
        return {
          items: responseData,
          pagesCount,
          totalElements,
        };
      }
);

// export const fetchPerfumesByInputText = createAsyncThunk<HeaderResponse<PerfumeResponse>, PerfumesSearchRequest>(
//     "perfumes/fetchPerfumesByInputText",
//     async (data) => {
//         const response = await RequestService.post(`${PERFUMES_SEARCH_TEXT}?page=${data.currentPage}`, data);
//         return {
//             items: response.data,
//             pagesCount: parseInt(response.headers["page-total-count"]),
//             totalElements: parseInt(response.headers["page-total-elements"])
//         };
//     }
// );

// GraphQL thunks
export const fetchPerfumesByQuery = createAsyncThunk<Array<PerfumeResponse>>("perfumes/fetchPerfumesByQuery", async () => {
    const response = await RequestService.post(PERFUMES_GRAPHQL_PERFUMES, { query: getAllPerfumesByQuery });
    return response.data.data.perfumes;
});

export const fetchPerfumesByIdsQuery = createAsyncThunk<Array<PerfumeResponse>, Array<number>>(
    "perfumes/fetchPerfumesByIdsQuery",
    async (ids) => {
        const response = await RequestService.post(PERFUMES_GRAPHQL_IDS, { query: gePerfumesByIdsQuery(ids) });
        return response.data.data.perfumesIds;
    }
);
