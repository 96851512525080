import React, { useState } from 'react';
import emailjs from 'emailjs-com';
import './EmailChat.css'; // Import your CSS file
import { MailOutlined, CloseOutlined } from '@ant-design/icons'; // Import the MailOutlined and CloseOutlined icons from Ant Design

const EmailChat = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [isOpen, setIsOpen] = useState(false); // State to manage chat box visibility

  const handleFormSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    // Your email.js service ID, template ID, and user ID
    const serviceId = 'service_r38ibt4';
    const templateId = 'template_8440cko';
    const userId = 'asbWp2m53z_VyU1-f';

    // Prepare the template parameters
    const templateParams = {
      from_name: name,
      from_email: email,
      message: message,
    };

    // Send the email using email.js
    emailjs
      .send(serviceId, templateId, templateParams, userId)
      .then((response) => {
        console.log('Email sent successfully:', response);
        // Reset the form fields
        setName('');
        setEmail('');
        setMessage('');
      })
      .catch((error) => {
        console.error('Email sending failed:', error);
      });
  };

  const toggleChatBox = () => {
    setIsOpen((prevIsOpen) => !prevIsOpen);
  };

  return (
    <div className={`email-chat-container ${isOpen ? 'open' : ''}`}>
      {isOpen ? (
        <form onSubmit={handleFormSubmit}>
          <div className="close-icon" onClick={toggleChatBox}>
            <CloseOutlined />
          </div>
          <label>
            Name:
            <input type="text" value={name} onChange={(e) => setName(e.target.value)} />
          </label>
          <br />
          <label>
            Email:
            <input type="email" value={email} onChange={(e) => setEmail(e.target.value)} />
          </label>
          <br />
          <label>
            Message:
            <textarea value={message} onChange={(e) => setMessage(e.target.value)} />
          </label>
          <br />
          <button type="submit">Send Email</button>
        </form>
      ) : (
        <div className="email-icon" onClick={toggleChatBox}>
          <MailOutlined style={{ fontSize: '24px', color: 'lightblue' }} />
        </div>
      )}
    </div>
  );
};

export default EmailChat;
