// export type BrandType = {
//     name: string;
//     url: string;
// };

// export const brandsItem: Array<BrandType> = [
//     { name: "Tom Ford", url: "https://perfumeswebstore.s3.us-west-2.amazonaws.com/Screenshot+2023-06-05+at+12.25.20+AM.png" },
//     { name: "Prada", url: "https://perfumeswebstore.s3.us-west-2.amazonaws.com/Screenshot+2023-06-05+at+12.25.20+AM.png" },
//     { name: "Dior", url: "https://i.ibb.co/wCkG15S/o-160.jpg" },
//     { name: "Versace", url: "https://i.ibb.co/0mFhYdV/o-97.jpg" },
//     { name: "Chanel", url: "https://i.ibb.co/QrNd9DL/o-30.jpg" },
//     { name: "Dolce&Gabbana", url: "https://i.ibb.co/Sycbcbv/o-56.jpg" },

//     { name: "Gucci", url: "https://i.ibb.co/z6gQzvg/o-67.jpg" },
//     { name: "Givenchy", url: "https://i.ibb.co/0hT49zf/o-66.jpg" },
//     { name: "Creed", url: "https://i.ibb.co/yR1mZP4/o-50.jpg" },
//     { name: "Jean Paul Gaultier", url: "https://i.ibb.co/tHFz6W2/o-72.jpg" },
//     { name: "Paco Rabanne", url: "https://i.ibb.co/k440VSk/o-88.jpg" },
//     { name: "Giorgio Armani", url: "https://i.ibb.co/qkkBXBT/o-65.jpg" },

//     { name: "Bvlgari", url: "https://i.ibb.co/58FfNSb/o-14.jpg" },
//     { name: "Calvin Klein", url: "https://i.ibb.co/98r6KXm/o-18.jpg" },
//     { name: "Hermes", url: "https://i.ibb.co/jL0gNjg/o-69.jpg" },
//     { name: "Hugo Boss", url: "https://i.ibb.co/RgY9GLG/o-70.jpg" },
//     { name: "Lancome", url: "https://i.ibb.co/Jkzsj7v/o-80.jpg" },
//     { name: "Burberry", url: "https://i.ibb.co/MRrJCgQ/o-104.jpg" },
// ];

import pic1 from './pic1.jpg';
import pic2 from './pic2.jpg';
import pic3 from './pic3.jpg';
import pic4 from './pic4.jpg';
import pic5 from './pic5.jpg';


export type BrandType = {
    name: string;
    url: string; // Change the property name from "imagePath" to "url"
};

export const brandsItem: Array<BrandType> = [
    { name: "Product1", url: pic1 }, // Use the imported local image directly
    { name: "Product2", url: pic2 },
    { name: "Product3", url: pic3 }, // Use the imported local image directly
    { name: "Product4", url: pic4 },
    { name: "Product5", url: pic5 }, // Use the imported local image directly
    { name: "Product6", url: pic2 },
    { name: "Product7", url: pic1 }, // Use the imported local image directly
    { name: "Product8", url: pic3 },
    { name: "Product10", url: pic4 }, // Use the imported local image directly
    { name: "Product11", url: pic5 },
    { name: "Product10", url: pic3 }, // Use the imported local image directly
    { name: "Product11", url: pic2 },
    { name: "Product12", url: pic1 }, // Use the imported local image directly
    { name: "Product13", url: pic2 },
    { name: "Product14", url: pic1 }, // Use the imported local image directly
    { name: "Product15", url: pic2 },
    // ...
];
